import filetype from 'magic-bytes.js';
import { err, ok, Result } from 'neverthrow';

const getGreatestCommonDivisor = (a: number, b: number): number =>
  b === 0 ? a : getGreatestCommonDivisor(b, a % b);

type Filetype = File | Blob;
export type ChecksBeforeUploadProps = {
  file: Filetype;
  checks: {
    imageMinSize?: number;
    imageMaxSize?: number;
    imageType?: string[];
    imageAspectRatio?: string[];
  };
};

const isFileSizeTooBig = (fileSize: number, maxSize: number): boolean => {
  return fileSize > maxSize;
};

const isFileSizeTooSmall = (fileSize: number, minSize: number): boolean => {
  return fileSize < minSize;
};

const isAllowedFileType = async (
  file: Filetype,
  allowedTypes: string[],
): Promise<boolean> => {
  const arrBuffer = await file.arrayBuffer();
  const currentFileTypes = filetype(new Uint8Array(arrBuffer));

  if (!currentFileTypes.length) {
    console.error('File type could not be determined', file);
    return false;
  }

  return currentFileTypes.some((fileType) =>
    allowedTypes.includes(fileType.typename),
  );
};

const isAllowedAspectRatio = async (
  file: Filetype,
  allowedRatios: string[],
): Promise<boolean> => {
  const image = new Image();
  image.src = URL.createObjectURL(file);
  await image.decode();
  const { naturalWidth, naturalHeight } = image;
  const gcd = getGreatestCommonDivisor(naturalWidth, naturalHeight);
  const aspectRatioOfImage = `${naturalWidth / gcd}:${naturalHeight / gcd}`;
  return allowedRatios.includes(aspectRatioOfImage);
};

export const checksBeforeUpload = async ({
  file,
  checks,
}: ChecksBeforeUploadProps): Promise<
  Result<ChecksBeforeUploadProps['file'], string[]>
> => {
  const errMessages: string[] = [];
  //check filesize
  const filesize = file.size;
  if (checks.imageMaxSize && isFileSizeTooBig(filesize, checks.imageMaxSize)) {
    errMessages.push(
      `File too large! Max size: ${checks.imageMaxSize / 1024 / 1024} MB`,
    );
  }

  if (
    checks.imageMinSize &&
    isFileSizeTooSmall(filesize, checks.imageMinSize)
  ) {
    errMessages.push(
      `File too small! Min size: ${checks.imageMinSize / 1024} KB`,
    );
  }
  //check magic-bytes
  if (checks.imageType && !(await isAllowedFileType(file, checks.imageType))) {
    errMessages.push(
      `Only accepts ${checks.imageType.join(',')} format` as const,
    );
  }

  //check aspectRatio
  if (
    checks.imageAspectRatio &&
    !(await isAllowedAspectRatio(file, checks.imageAspectRatio))
  ) {
    errMessages.push(
      `Only accepts ${checks.imageAspectRatio.join(', ')} aspect ratio` as const,
    );
  }
  if (errMessages.length > 0) return err(errMessages);

  return ok(file);
};
