/* eslint-disable @typescript-eslint/no-unnecessary-type-parameters */
import type { SUCCESS_RESPONSE } from '@gql_codegen/typings/api/api_success';
import { axiosInstance } from '@shared/axiosInstance';
import { AxiosHeaders } from 'axios';
import { z } from 'zod';
import { FAIL_RESPONSE } from './typings/api/api_fail';

export const checkIfFailResponse = (
  response: unknown,
): response is FAIL_RESPONSE => {
  const failSchema = z.object({
    errors: z.array(z.unknown()),
  });

  return failSchema.safeParse(response).success;
};

export const api =
  <TData, TVariables>(
    gqlString: string,
    variables?: TVariables,
    options?: RequestInit['headers'],
  ): (() => Promise<TData>) =>
  async () => {
    const headers = new Headers(options);
    const operation = gqlString.trim();
    const response = await axiosInstance.post<
      SUCCESS_RESPONSE<TData> | FAIL_RESPONSE
    >(
      '/api/v1/retail-classifieds-content-gql/graphql',
      {
        query: operation,
        variables,
      },
      {
        timeout: 60000,
        headers: headers as unknown as AxiosHeaders,
      },
    );

    //in case of error with 200 HTTP Code we throw it and react-query will handle it
    if (checkIfFailResponse(response.data)) throw response.data;

    return response.data.data;
  };

export default api;
