export const DEFAULT_VALIDATION_CONFIG = {
  minPlaceholderPosition: 1,
  maxPlaceholderPosition: 100,
  minPlaceholdersAllowed: 0,
  maxPlaceholdersAllowed: 100,
  imageAspectRatio: '4:3',
  imageMaxSize: 10000000,
  imageMinSize: 0,
  placeholderImageType: 'jpg',
};
