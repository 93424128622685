import { CloseOutlined } from '@ant-design/icons';
import { css } from '@styled-system/css';
import { QueryClientProvider } from '@tanstack/react-query';
import { createRouter, RouterProvider } from '@tanstack/react-router';
import { App, ConfigProvider, notification, Spin } from 'antd';
import { ANTD_CLASSNAMES_PREFIX } from '../constants/antd';
import { routeTree } from '../routeTree.gen';
import { queryClient } from './query-client';

// Create a new router instance
const router = createRouter({
  routeTree,
  context: {
    queryClient,
  },
  defaultPreload: 'intent',
  // Since we're using React Query, we don't want loader calls to ever be stale
  // This will ensure that the loader is always called when the route is preloaded or visited
  defaultPreloadStaleTime: 0,
  defaultPendingComponent: Spin,
});

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

notification.config({
  top: 55,
  prefixCls: `${ANTD_CLASSNAMES_PREFIX}-notification`,
  closeIcon: <CloseOutlined data-qa-selector="notification-close" />,
  props: {
    'data-testid': 'classified-content-notification',
  },
});

export const RootComponent = () => {
  return (
    <ConfigProvider
      theme={{
        token: {
          borderRadius: 2,
          fontFamily: "'Source Sans Pro', Calibri, Candara, Arial, sans-serif",
          colorTextDisabled: 'black',
        },
      }}
      notification={{
        closeIcon: <CloseOutlined data-qa-selector="notification-close" />,
      }}
      prefixCls={ANTD_CLASSNAMES_PREFIX}
    >
      <App>
        <QueryClientProvider client={queryClient}>
          <div
            className={css({ p: 5, pt: 0, m: 'auto' })}
            data-qa-selector="rootWrapper"
          >
            <RouterProvider router={router} />
          </div>
        </QueryClientProvider>
      </App>
    </ConfigProvider>
  );
};
