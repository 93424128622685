import { PageHeader } from '@ant-design/pro-components';
import { Route as OverviewRoute } from '@routes/$lang/classifieds-content';
import { css } from '@styled-system/css';
import { Link } from '@tanstack/react-router';
import { Button, Space, Spin } from 'antd';

type ActionBarProps = {
  inProgress: boolean;
};

export const ActionBar: React.FC<ActionBarProps> = ({
  inProgress = false,
}: ActionBarProps) => {
  return (
    <PageHeader
      className={css({
        position: 'sticky !important',
        zIndex: '11',
        top: '40px',
        // eslint-disable-next-line @pandacss/no-hardcoded-color
        backgroundColor: 'white !important',
        boxShadow: '0 8px 10px -10px',
        boxShadowColor: 'black',
        marginBottom: '48px !important',
        padding: '16px 20px !important',
        isolation: 'isolate',
      })}
      ghost={false}
      title={
        <Space>
          <Link
            to={OverviewRoute.to}
            params={{ lang: 'en' }}
            data-qa-selector="back-to-overview-button"
          >
            <span>Classifieds Content</span>
          </Link>
          <span>/</span>
          <span>Classifieds Content Template Creator</span>
        </Space>
      }
      extra={
        <Space>
          {inProgress && (
            <div data-qa-selector="ProgressSpiner">
              <Spin size="large" />
            </div>
          )}
          <Button
            type="primary"
            size="large"
            data-qa-selector="reset-button"
            htmlType="reset"
            disabled={inProgress}
            danger
          >
            Reset
          </Button>
          <Button
            type="primary"
            size="large"
            data-qa-selector="create-button"
            htmlType="submit"
            disabled={inProgress}
          >
            Create
          </Button>
        </Space>
      }
    />
  );
};
