import { CreateFormSchemaType } from '@features/CreatePage/model/create-form-model';
import { assertUnreachable } from '@src/shared/utils/assertUnreachable';
import { FieldPath, UseFormSetValue } from 'react-hook-form';

type editFormLogicProps = {
  setValue: UseFormSetValue<CreateFormSchemaType>;
  clearUppy: () => void;
};
type EditFormLogicFn = (
  props: editFormLogicProps,
) => (
  fieldName: Extract<FieldPath<CreateFormSchemaType>, 'country' | 'platform'>,
) => void;

export const createFormResetLogic: EditFormLogicFn = ({
  clearUppy,
  setValue,
}) => {
  return (fieldName): void => {
    switch (fieldName) {
      case 'country': {
        setValue('platform', '');
        setValue('accountId', '');
        setValue('placeholders', []);
        clearUppy();
        return;
      }

      case 'platform': {
        setValue('accountId', '');
        setValue('placeholders', []);
        clearUppy();
        return;
      }

      default:
        return assertUnreachable(fieldName);
    }
  };
};
