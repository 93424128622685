const getCookieValue = (cookieName: string): string | null => {
  if (!document.cookie) return null;

  return (
    document.cookie
      .split('; ')
      .find((row) => row.startsWith(`${cookieName}=`))
      ?.split('=')[1] ?? null
  );
};

export default getCookieValue;
