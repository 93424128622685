import { useGetDictionariesQuery } from '@gql_codegen/classifieds-content-types';
import { useConvertGeneratedQueryToSuspense } from '@src/shared/hooks/use-convert-generated-query-to-suspence';
import { useGetCurrentContentTemplateQuery } from './use-current-content-template';

export const useAccountQuery = () => {
  const contentTeplateQuery = useGetCurrentContentTemplateQuery();

  return useConvertGeneratedQueryToSuspense(
    useGetDictionariesQuery,
    undefined,
    {
      select: (data) =>
        data.getDictionaries.accounts.find(
          (account) =>
            account.name ===
            contentTeplateQuery.data.getContentTemplate.account,
        ),
    },
  );
};
