import { useGetContentTemplateQuery } from '@gql_codegen/classifieds-content-types';
import { Route as EditRoute } from '@routes/$lang/classifieds-content/edit/$editID';
import { useConvertGeneratedQueryToSuspense } from '@src/shared/hooks/use-convert-generated-query-to-suspence';

export const useGetCurrentContentTemplateQuery = () => {
  const { editID } = EditRoute.useParams();

  return useConvertGeneratedQueryToSuspense(useGetContentTemplateQuery, {
    uuid: editID,
  });
};
