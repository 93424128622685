// THIS FILE IS GENERATED, DO NOT EDIT!
/* istanbul ignore next */
/* eslint-disable */
/* @ts-nocheck */
import type { FAIL_RESPONSE } from '@gql_codegen/typings/api/api_fail';
import type { AxiosError } from 'axios';

import { z } from 'zod';
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
} from '@tanstack/react-query';
import { api } from './api';
export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  UUID: { input: string; output: string };
};

export type PresignedUrlRequestInput = {
  accountId: Scalars['UUID']['input'];
  category: Category;
  fileNames: Array<Scalars['String']['input']>;
  uploadType: UploadType;
};

export type ContentTemplateCreateInput = {
  accountId: Scalars['UUID']['input'];
  category: Category;
  name: Scalars['String']['input'];
  placeholders: Array<PlaceholderImageInput>;
};

export type ContentTemplateUpdateInput = {
  id: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
  placeholders: Array<PlaceholderImageInput>;
};

export type PlaceholderImageInput = {
  url: Scalars['String']['input'];
  position: Scalars['Int']['input'];
};

export type Dictionary = {
  __typename?: 'Dictionary';
  contentTemplateCountries: Array<ContentTemplateCountry>;
  platforms: Array<Platform>;
  accounts: Array<Account>;
  categories: Array<Scalars['String']['output']>;
  classifiedsContentTemplateValidationConfigs: Array<ClassifiedContentTemplateValidationConfig>;
};

export type ContentTemplate = {
  __typename?: 'ContentTemplate';
  id: Scalars['UUID']['output'];
  country: Scalars['String']['output'];
  account: Scalars['String']['output'];
  platform: Scalars['String']['output'];
  category: Category;
  name: Scalars['String']['output'];
  placeholders: Array<PlaceholderImage>;
};

export type PlaceholderImage = {
  __typename?: 'PlaceholderImage';
  url: Scalars['String']['output'];
  position: Scalars['Int']['output'];
};

export type ContentTemplateCountry = {
  __typename?: 'ContentTemplateCountry';
  country: Scalars['String']['output'];
};

export type Platform = {
  __typename?: 'Platform';
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  country: Scalars['String']['output'];
};

export type Account = {
  __typename?: 'Account';
  id: Scalars['UUID']['output'];
  platformId: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
};

export type PresignedUrl = {
  __typename?: 'PresignedUrl';
  url: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
  headers: Array<Maybe<UploadHeader>>;
};

export type UploadHeader = {
  __typename?: 'UploadHeader';
  name: Scalars['String']['output'];
  values: Array<Scalars['String']['output']>;
};

export type ClassifiedContentTemplateValidationConfig = {
  __typename?: 'ClassifiedContentTemplateValidationConfig';
  classifiedCode: Scalars['String']['output'];
  config: ContentTemplateValidationConfig;
};

export type ContentTemplateValidationConfig = {
  __typename?: 'ContentTemplateValidationConfig';
  maxPlaceholderPosition: Scalars['Int']['output'];
  minPlaceholderPosition: Scalars['Int']['output'];
  placeholderImageType: Scalars['String']['output'];
  imageMaxSize: Scalars['Int']['output'];
  imageMinSize: Scalars['Int']['output'];
  imageAspectRatio: Scalars['String']['output'];
  maxPlaceholdersAllowed: Scalars['Int']['output'];
  minPlaceholdersAllowed: Scalars['Int']['output'];
};

export const Category = {
  Other: 'OTHER',
  Default: 'DEFAULT',
  Showroom: 'SHOWROOM',
} as const;

export type Category = (typeof Category)[keyof typeof Category];
export const UploadType = {
  Placeholder: 'PLACEHOLDER',
} as const;

export type UploadType = (typeof UploadType)[keyof typeof UploadType];
export type Mutation = {
  __typename?: 'Mutation';
  createContentTemplate?: Maybe<ContentTemplate>;
  updateContentTemplate?: Maybe<ContentTemplate>;
};

export type MutationCreateContentTemplateArgs = {
  createInput: ContentTemplateCreateInput;
};

export type MutationUpdateContentTemplateArgs = {
  updateInput: ContentTemplateUpdateInput;
};

export type Query = {
  __typename?: 'Query';
  getDictionaries: Dictionary;
  getPresignedUrls: Array<PresignedUrl>;
  getContentTemplates: Array<ContentTemplate>;
  getContentTemplate: ContentTemplate;
};

export type QueryGetPresignedUrlsArgs = {
  request: PresignedUrlRequestInput;
};

export type QueryGetContentTemplateArgs = {
  id: Scalars['UUID']['input'];
};

export type GetTemplatesQueryVariables = Exact<{ [key: string]: never }>;

export type GetTemplatesQuery = {
  __typename?: 'Query';
  getContentTemplates: Array<{
    __typename?: 'ContentTemplate';
    id: string;
    country: string;
    account: string;
    platform: string;
    category: Category;
    name: string;
    placeholders: Array<{
      __typename?: 'PlaceholderImage';
      url: string;
      position: number;
    }>;
  }>;
};

export type GetPresignedUrlsQueryVariables = Exact<{
  accountId: Scalars['UUID']['input'];
  category: Category;
  fileNames: Array<Scalars['String']['input']> | Scalars['String']['input'];
  uploadType: UploadType;
}>;

export type GetPresignedUrlsQuery = {
  __typename?: 'Query';
  getPresignedUrls: Array<{
    __typename?: 'PresignedUrl';
    url: string;
    fileName: string;
    headers: Array<
      | { __typename?: 'UploadHeader'; name: string; values: Array<string> }
      | null
      | undefined
    >;
  }>;
};

export type GetDictionariesQueryVariables = Exact<{ [key: string]: never }>;

export type GetDictionariesQuery = {
  __typename?: 'Query';
  getDictionaries: {
    __typename?: 'Dictionary';
    categories: Array<string>;
    contentTemplateCountries: Array<{
      __typename?: 'ContentTemplateCountry';
      country: string;
    }>;
    platforms: Array<{
      __typename?: 'Platform';
      name: string;
      id: string;
      country: string;
    }>;
    accounts: Array<{
      __typename?: 'Account';
      platformId: string;
      name: string;
      id: string;
    }>;
    classifiedsContentTemplateValidationConfigs: Array<{
      __typename?: 'ClassifiedContentTemplateValidationConfig';
      classifiedCode: string;
      config: {
        __typename?: 'ContentTemplateValidationConfig';
        maxPlaceholderPosition: number;
        minPlaceholderPosition: number;
        placeholderImageType: string;
        imageMaxSize: number;
        imageMinSize: number;
        imageAspectRatio: string;
        maxPlaceholdersAllowed: number;
        minPlaceholdersAllowed: number;
      };
    }>;
  };
};

export type GetContentTemplateQueryVariables = Exact<{
  uuid: Scalars['UUID']['input'];
}>;

export type GetContentTemplateQuery = {
  __typename?: 'Query';
  getContentTemplate: {
    __typename?: 'ContentTemplate';
    id: string;
    country: string;
    account: string;
    platform: string;
    category: Category;
    name: string;
    placeholders: Array<{
      __typename?: 'PlaceholderImage';
      url: string;
      position: number;
    }>;
  };
};

export type UpdateContentTemplateMutationVariables = Exact<{
  updateInput: ContentTemplateUpdateInput;
}>;

export type UpdateContentTemplateMutation = {
  __typename?: 'Mutation';
  updateContentTemplate?:
    | {
        __typename?: 'ContentTemplate';
        id: string;
        country: string;
        account: string;
        platform: string;
        category: Category;
        name: string;
        placeholders: Array<{
          __typename?: 'PlaceholderImage';
          url: string;
          position: number;
        }>;
      }
    | null
    | undefined;
};

export type CreateContentTemplateMutationVariables = Exact<{
  createInput: ContentTemplateCreateInput;
}>;

export type CreateContentTemplateMutation = {
  __typename?: 'Mutation';
  createContentTemplate?:
    | {
        __typename?: 'ContentTemplate';
        id: string;
        country: string;
        account: string;
        platform: string;
        category: Category;
        name: string;
        placeholders: Array<{
          __typename?: 'PlaceholderImage';
          url: string;
          position: number;
        }>;
      }
    | null
    | undefined;
};

export const GetTemplatesDocument = `
    query getTemplates {
  getContentTemplates {
    id
    country
    account
    platform
    category
    name
    placeholders {
      url
      position
    }
  }
}
    `;

export const useGetTemplatesQuery = <
  TData = GetTemplatesQuery,
  TError = FAIL_RESPONSE | AxiosError<FAIL_RESPONSE>,
>(
  variables?: GetTemplatesQueryVariables,
  options?: Omit<
    UseQueryOptions<GetTemplatesQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<GetTemplatesQuery, TError, TData>['queryKey'];
  },
) => {
  return useQuery<GetTemplatesQuery, TError, TData>({
    queryKey:
      variables === undefined ? ['getTemplates'] : ['getTemplates', variables],
    queryFn: api<GetTemplatesQuery, GetTemplatesQueryVariables>(
      GetTemplatesDocument,
      variables,
    ),
    ...options,
  });
};

useGetTemplatesQuery.getKey = (variables?: GetTemplatesQueryVariables) =>
  variables === undefined ? ['getTemplates'] : ['getTemplates', variables];

useGetTemplatesQuery.fetcher = (
  variables?: GetTemplatesQueryVariables,
  options?: RequestInit['headers'],
) =>
  api<GetTemplatesQuery, GetTemplatesQueryVariables>(
    GetTemplatesDocument,
    variables,
    options,
  );

export const GetPresignedUrlsDocument = `
    query getPresignedUrls($accountId: UUID!, $category: Category!, $fileNames: [String!]!, $uploadType: UploadType!) {
  getPresignedUrls(
    request: {accountId: $accountId, category: $category, fileNames: $fileNames, uploadType: $uploadType}
  ) {
    url
    fileName
    headers {
      name
      values
    }
  }
}
    `;

export const useGetPresignedUrlsQuery = <
  TData = GetPresignedUrlsQuery,
  TError = FAIL_RESPONSE | AxiosError<FAIL_RESPONSE>,
>(
  variables: GetPresignedUrlsQueryVariables,
  options?: Omit<
    UseQueryOptions<GetPresignedUrlsQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      GetPresignedUrlsQuery,
      TError,
      TData
    >['queryKey'];
  },
) => {
  return useQuery<GetPresignedUrlsQuery, TError, TData>({
    queryKey: ['getPresignedUrls', variables],
    queryFn: api<GetPresignedUrlsQuery, GetPresignedUrlsQueryVariables>(
      GetPresignedUrlsDocument,
      variables,
    ),
    ...options,
  });
};

useGetPresignedUrlsQuery.getKey = (
  variables: GetPresignedUrlsQueryVariables,
) => ['getPresignedUrls', variables];

useGetPresignedUrlsQuery.fetcher = (
  variables: GetPresignedUrlsQueryVariables,
  options?: RequestInit['headers'],
) =>
  api<GetPresignedUrlsQuery, GetPresignedUrlsQueryVariables>(
    GetPresignedUrlsDocument,
    variables,
    options,
  );

export const GetDictionariesDocument = `
    query getDictionaries {
  getDictionaries {
    contentTemplateCountries {
      country
    }
    platforms {
      name
      id
      country
    }
    accounts {
      platformId
      name
      id
    }
    categories
    classifiedsContentTemplateValidationConfigs {
      classifiedCode
      config {
        maxPlaceholderPosition
        minPlaceholderPosition
        placeholderImageType
        imageMaxSize
        imageMinSize
        imageAspectRatio
        maxPlaceholdersAllowed
        minPlaceholdersAllowed
      }
    }
  }
}
    `;

export const useGetDictionariesQuery = <
  TData = GetDictionariesQuery,
  TError = FAIL_RESPONSE | AxiosError<FAIL_RESPONSE>,
>(
  variables?: GetDictionariesQueryVariables,
  options?: Omit<
    UseQueryOptions<GetDictionariesQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<GetDictionariesQuery, TError, TData>['queryKey'];
  },
) => {
  return useQuery<GetDictionariesQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['getDictionaries']
        : ['getDictionaries', variables],
    queryFn: api<GetDictionariesQuery, GetDictionariesQueryVariables>(
      GetDictionariesDocument,
      variables,
    ),
    ...options,
  });
};

useGetDictionariesQuery.getKey = (variables?: GetDictionariesQueryVariables) =>
  variables === undefined
    ? ['getDictionaries']
    : ['getDictionaries', variables];

useGetDictionariesQuery.fetcher = (
  variables?: GetDictionariesQueryVariables,
  options?: RequestInit['headers'],
) =>
  api<GetDictionariesQuery, GetDictionariesQueryVariables>(
    GetDictionariesDocument,
    variables,
    options,
  );

export const GetContentTemplateDocument = `
    query getContentTemplate($uuid: UUID!) {
  getContentTemplate(id: $uuid) {
    id
    country
    account
    platform
    category
    name
    placeholders {
      url
      position
    }
  }
}
    `;

export const useGetContentTemplateQuery = <
  TData = GetContentTemplateQuery,
  TError = FAIL_RESPONSE | AxiosError<FAIL_RESPONSE>,
>(
  variables: GetContentTemplateQueryVariables,
  options?: Omit<
    UseQueryOptions<GetContentTemplateQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      GetContentTemplateQuery,
      TError,
      TData
    >['queryKey'];
  },
) => {
  return useQuery<GetContentTemplateQuery, TError, TData>({
    queryKey: ['getContentTemplate', variables],
    queryFn: api<GetContentTemplateQuery, GetContentTemplateQueryVariables>(
      GetContentTemplateDocument,
      variables,
    ),
    ...options,
  });
};

useGetContentTemplateQuery.getKey = (
  variables: GetContentTemplateQueryVariables,
) => ['getContentTemplate', variables];

useGetContentTemplateQuery.fetcher = (
  variables: GetContentTemplateQueryVariables,
  options?: RequestInit['headers'],
) =>
  api<GetContentTemplateQuery, GetContentTemplateQueryVariables>(
    GetContentTemplateDocument,
    variables,
    options,
  );

export const UpdateContentTemplateDocument = `
    mutation updateContentTemplate($updateInput: ContentTemplateUpdateInput!) {
  updateContentTemplate(updateInput: $updateInput) {
    id
    country
    account
    platform
    category
    name
    placeholders {
      url
      position
    }
  }
}
    `;

export const useUpdateContentTemplateMutation = <
  TError = FAIL_RESPONSE | AxiosError<FAIL_RESPONSE>,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateContentTemplateMutation,
    TError,
    UpdateContentTemplateMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    UpdateContentTemplateMutation,
    TError,
    UpdateContentTemplateMutationVariables,
    TContext
  >({
    mutationKey: ['updateContentTemplate'],
    mutationFn: (variables?: UpdateContentTemplateMutationVariables) =>
      api<
        UpdateContentTemplateMutation,
        UpdateContentTemplateMutationVariables
      >(UpdateContentTemplateDocument, variables)(),
    ...options,
  });
};

useUpdateContentTemplateMutation.fetcher = (
  variables: UpdateContentTemplateMutationVariables,
  options?: RequestInit['headers'],
) =>
  api<UpdateContentTemplateMutation, UpdateContentTemplateMutationVariables>(
    UpdateContentTemplateDocument,
    variables,
    options,
  );

export const CreateContentTemplateDocument = `
    mutation createContentTemplate($createInput: ContentTemplateCreateInput!) {
  createContentTemplate(createInput: $createInput) {
    id
    country
    account
    platform
    category
    name
    placeholders {
      url
      position
    }
  }
}
    `;

export const useCreateContentTemplateMutation = <
  TError = FAIL_RESPONSE | AxiosError<FAIL_RESPONSE>,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    CreateContentTemplateMutation,
    TError,
    CreateContentTemplateMutationVariables,
    TContext
  >,
) => {
  return useMutation<
    CreateContentTemplateMutation,
    TError,
    CreateContentTemplateMutationVariables,
    TContext
  >({
    mutationKey: ['createContentTemplate'],
    mutationFn: (variables?: CreateContentTemplateMutationVariables) =>
      api<
        CreateContentTemplateMutation,
        CreateContentTemplateMutationVariables
      >(CreateContentTemplateDocument, variables)(),
    ...options,
  });
};

useCreateContentTemplateMutation.fetcher = (
  variables: CreateContentTemplateMutationVariables,
  options?: RequestInit['headers'],
) =>
  api<CreateContentTemplateMutation, CreateContentTemplateMutationVariables>(
    CreateContentTemplateDocument,
    variables,
    options,
  );

type Properties<T> = Required<{
  [K in keyof T]: z.ZodType<T[K], any, T[K]>;
}>;

type definedNonNullAny = {};

export const isDefinedNonNullAny = (v: any): v is definedNonNullAny =>
  v !== undefined && v !== null;

export const definedNonNullAnySchema = z
  .any()
  .refine((v) => isDefinedNonNullAny(v));

export const CategorySchema = z.nativeEnum(Category);

export const UploadTypeSchema = z.nativeEnum(UploadType);

export function PresignedUrlRequestInputSchema(): z.ZodObject<
  Properties<PresignedUrlRequestInput>
> {
  return z.object({
    accountId: z.string().uuid(),
    category: CategorySchema,
    fileNames: z.array(z.string()),
    uploadType: UploadTypeSchema,
  });
}

export function ContentTemplateCreateInputSchema(): z.ZodObject<
  Properties<ContentTemplateCreateInput>
> {
  return z.object({
    accountId: z.string().uuid(),
    category: CategorySchema,
    name: z.string(),
    placeholders: z.array(z.lazy(() => PlaceholderImageInputSchema())),
  });
}

export function ContentTemplateUpdateInputSchema(): z.ZodObject<
  Properties<ContentTemplateUpdateInput>
> {
  return z.object({
    id: z.string().uuid(),
    name: z.string(),
    placeholders: z.array(z.lazy(() => PlaceholderImageInputSchema())),
  });
}

export function PlaceholderImageInputSchema(): z.ZodObject<
  Properties<PlaceholderImageInput>
> {
  return z.object({
    url: z.string(),
    position: z.number().int(),
  });
}
