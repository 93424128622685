import { CreateFormSchemaType } from '@features/CreatePage/model/create-form-model';
import {
  Category,
  ContentTemplateValidationConfig,
  UploadType,
  useGetPresignedUrlsQuery,
} from '@gql_codegen/classifieds-content-types';
import AwsS3 from '@uppy/aws-s3';
import Uppy, { UppyFile } from '@uppy/core';
import DropTarget from '@uppy/drop-target';
import { useUppyEvent, useUppyState } from '@uppy/react';
import ThumbnailGenerator from '@uppy/thumbnail-generator';
import { App } from 'antd';
import { err, ok, Result, ResultAsync } from 'neverthrow';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Control,
  useFieldArray,
  UseFormGetValues,
  UseFormTrigger,
  useWatch,
} from 'react-hook-form';
import {
  checksBeforeUpload,
  ChecksBeforeUploadProps,
} from '../domain/checks-before-upload';

const FILE_LIMIT_DEFAULT = Infinity;

const findFileIndex = (
  files: UppyFile<Record<string, unknown>, Record<string, unknown>>[],
  fileId: string,
): Result<number, false> => {
  const fileIdx = files.findIndex((file) => file.id === fileId);

  if (fileIdx === -1) return err(false);

  return ok(fileIdx);
};

type UppyFileMeta = {
  accountId: string;
  category: Category;
  uuid: string;
};
type UppyFileBody = Record<string, unknown>;

export type UppyWithForm = Uppy<UppyFileMeta, UppyFileBody>;
export type CreateUppyProps = {
  fileLimit?: number;
};

const createUppy = ({
  fileLimit = FILE_LIMIT_DEFAULT,
}: CreateUppyProps): UppyWithForm => {
  return new Uppy<UppyFileMeta, UppyFileBody>({
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: fileLimit,
      requiredMetaFields: ['accountId', 'category', 'uuid'],
    },
  })
    .use(DropTarget<UppyFileMeta, UppyFileBody>, {
      target: document.body,
    })
    .use(ThumbnailGenerator<UppyFileMeta, UppyFileBody>, {
      waitForThumbnailsBeforeUpload: true,
      thumbnailWidth: 500,
    })
    .use(AwsS3<UppyFileMeta, UppyFileBody>, {
      endpoint: '',
      shouldUseMultipart: false,
      getUploadParameters: async (file) => {
        const urlResult = await ResultAsync.fromPromise(
          useGetPresignedUrlsQuery.fetcher({
            accountId: file.meta.accountId,
            category: file.meta.category,
            fileNames: file.meta.uuid + '.' + file.extension,
            uploadType: UploadType.Placeholder,
          })(),
          (err) =>
            new Error(`[Uppy::AwsS3]=> failed to get presigned url`, {
              cause: err,
            }),
        );

        if (urlResult.isErr()) throw urlResult.error;
        const url = urlResult.value.getPresignedUrls[0]?.url;
        if (typeof url !== 'string') throw new Error('[Uppy::AwsS3]=> no url');

        return {
          method: 'PUT',
          url,
          fields: {},
          headers: {
            'x-amz-acl': 'bucket-owner-full-control',
            'content-type': file.type,
          },
        };
      },
    });
};

type UseUppyProps = {
  validationConfig: ContentTemplateValidationConfig;
  control: Control<CreateFormSchemaType>;
  trigger: UseFormTrigger<CreateFormSchemaType>;
  getValues: UseFormGetValues<CreateFormSchemaType>;
};

export const useUppyWithForm = ({
  validationConfig,
  control,
  getValues,
  trigger,
}: UseUppyProps) => {
  const { notification } = App.useApp();

  const [accountId, category] = useWatch({
    control,
    name: ['accountId', 'category'],
  });

  const {
    fields: placeholders,
    update,
    remove,
  } = useFieldArray({
    control,
    name: 'placeholders',
  });

  const checks = useMemo((): ChecksBeforeUploadProps['checks'] => {
    return {
      imageMinSize: validationConfig.imageMinSize,
      imageMaxSize: validationConfig.imageMaxSize,
      imageType: [validationConfig.placeholderImageType],
      imageAspectRatio: [validationConfig.imageAspectRatio],
    };
  }, [validationConfig]);

  const [uppy] = useState(() =>
    createUppy({
      fileLimit: validationConfig.maxPlaceholdersAllowed,
    }),
  );

  const files = useUppyState(uppy, (state) => state.files);

  useEffect(() => {
    if (validationConfig.maxPlaceholdersAllowed)
      uppy.setOptions({
        restrictions: {
          maxNumberOfFiles: validationConfig.maxPlaceholdersAllowed,
        },
      });
  }, [validationConfig.maxPlaceholdersAllowed, uppy]);

  useUppyEvent(uppy, 'thumbnail:generated', (file, preview) => {
    const fileIdxResult = findFileIndex(Object.values(files), file.id);

    if (fileIdxResult.isErr())
      return console.error('[Uppy::upload-success] => no file idx');

    const position = getValues(`placeholders.${fileIdxResult.value}.position`);
    update(fileIdxResult.value, {
      position,
      url: preview,
    });
  });

  useUppyEvent(uppy, 'upload-success', (file, { uploadURL }) => {
    if (!uploadURL)
      return console.error('[Uppy::upload-success] => no upload url');

    const fileIdxResult = findFileIndex(Object.values(files), file?.id ?? '');

    if (fileIdxResult.isErr())
      return console.error('[Uppy::upload-success] => no file idx');

    const position = getValues(`placeholders.${fileIdxResult.value}.position`);
    update(fileIdxResult.value, {
      position,
      url: uploadURL,
    });

    void trigger('placeholders');
  });

  useUppyEvent(uppy, 'upload-error', (file, error) => {
    notification.error({
      message: (
        <span data-qa-selector="create-form-upload-error">{error.message}</span>
      ),
    });
    if (!file?.id) return console.error('[Uppy::upload-error]=> no file id!!!');

    uppy.removeFile(file.id);
    const fileIdxResult = findFileIndex(Object.values(files), file.id);
    if (fileIdxResult.isOk()) remove(fileIdxResult.value);
  });

  const handleFileUpload = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;
      if (!files)
        return console.info(
          '[FileUpload::handleFileUpload]=> no files were provided',
        );

      for (const file of Array.from(files)) {
        checksBeforeUpload({
          file,
          checks: checks,
        })
          .then((checksResult) => {
            if (checksResult.isErr()) {
              checksResult.error.map((error) =>
                notification.error({
                  message: (
                    <span data-qa-selector="create-form-validation-error">
                      {error}
                    </span>
                  ),
                }),
              );
              return;
            }

            uppy.addFile({
              name: file.name,
              type: file.type,
              data: file,
              meta: {
                accountId: accountId,
                category: category,
                uuid: crypto.randomUUID(),
              },
            });
          })
          .catch((err: unknown) => {
            console.error(
              '[FileUpload::handleFileUpload::checksBeforeUpload]=>',
              err,
            );
            notification.error({
              message:
                err instanceof Error
                  ? err.message
                  : 'Unexpected error during image validation',
            });
          });
        event.target.value = '';
      }
    },
    [checks, notification, uppy, accountId, category],
  );

  const clearUppy = () => {
    uppy.cancelAll();
    uppy.clear();
  };

  return {
    uppy,
    handleFileUpload,
    placeholders,
    remove,
    clearUppy,
  };
};
