// uppyStore.ts
import { State, Store } from '@uppy/core';
import { omit } from 'radash';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import {
  UppyFileBody,
  UppyFileMeta,
  UppyWithFormFile,
} from '../entities/edit-form/file-upload/hooks/use-uppy-with-form';

export type UppyZustandEditStore = State<UppyFileMeta, UppyFileBody> &
  UppyZustandStoreActions;

interface UppyZustandStoreActions {
  addFile: (file: UppyWithFormFile) => void;
  removeFile: (fileId: UppyWithFormFile['id']) => void;
  clearFiles: () => void;
}

export const useUppyZustandEditStore = create<UppyZustandEditStore>()(
  devtools(
    immer((set) => ({
      totalProgress: 0,
      allowNewUpload: true,
      error: null,
      recoveredState: null,
      plugins: {},
      files: {},
      currentUploads: {},
      capabilities: {
        uploadProgress: true,
        individualCancellation: true,
        resumableUploads: false,
      },
      meta: {} as UppyFileMeta,
      info: [],
      addFile: (file) =>
        set((state) => {
          state.files[file.id] = file;
        }),
      removeFile: (fileId) =>
        set((state) => {
          state.files = omit(state.files, [fileId]);
        }),
      clearFiles: () => set({ files: {} }),
    })),
    {
      name: 'UppyZustandStore',
      anonymousActionType: 'UppyZustandStoreAction',
    },
  ),
);

export class ZustandUppyEditStoreMapper implements Store<UppyZustandEditStore> {
  getState = () => {
    return useUppyZustandEditStore.getState();
  };

  setState = (patch: Partial<UppyZustandEditStore>) => {
    useUppyZustandEditStore.setState({ ...patch });
  };

  subscribe: Store<UppyZustandEditStore>['subscribe'] = (listener) => {
    const unsubscribe = useUppyZustandEditStore.subscribe((state, prevState) =>
      listener(prevState, state),
    );
    return unsubscribe;
  };
}
