import {
  DefaultError,
  QueryFunction,
  QueryKey,
  useSuspenseQuery,
  UseSuspenseQueryOptions,
} from '@tanstack/react-query';

type GeneratedQueryLike<TArgs, TReturn> = {
  getKey: (...args: TArgs[]) => QueryKey;
  fetcher: (...args: TArgs[]) => QueryFunction<TReturn>;
};

export const useConvertGeneratedQueryToSuspense = <
  const T,
  const TReturn,
  const TError = DefaultError,
  const TData = TReturn,
>(
  query: GeneratedQueryLike<T, TReturn>,
  args: NoInfer<T>,
  useSuspenseQueryProps?: Omit<
    UseSuspenseQueryOptions<TReturn, TError, TData>,
    'queryKey' | 'queryFn'
  >,
) => {
  return useSuspenseQuery({
    queryKey: query.getKey(args),
    queryFn: query.fetcher(args),
    ...useSuspenseQueryProps,
  });
};
