import { useGetDictionariesQuery } from '@gql_codegen/classifieds-content-types';
import { DEFAULT_VALIDATION_CONFIG } from '@src/shared/configs/default-validation-config';
import { useConvertGeneratedQueryToSuspense } from '@src/shared/hooks/use-convert-generated-query-to-suspence';
import { useGetCurrentContentTemplateQuery } from './use-current-content-template';

export const useValidationConfigQuery = () => {
  const contentTemplateQuery = useGetCurrentContentTemplateQuery();

  return useConvertGeneratedQueryToSuspense(
    useGetDictionariesQuery,
    undefined,
    {
      select: (data) =>
        data.getDictionaries.classifiedsContentTemplateValidationConfigs.find(
          (config) =>
            config.classifiedCode ===
            contentTemplateQuery.data.getContentTemplate.platform,
        ) ?? { config: DEFAULT_VALIDATION_CONFIG },
    },
  );
};
