import { EditPage } from '@features/edit-page';
import {
  useGetContentTemplateQuery,
  useGetDictionariesQuery,
} from '@gql_codegen/classifieds-content-types';
import { formatServerError } from '@src/shared/formating/format-server-error';
import { VStack } from '@styled-system/jsx';
import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import {
  createFileRoute,
  ErrorRouteComponent,
  notFound,
  useRouter,
} from '@tanstack/react-router';
import { Button, notification } from 'antd';
import { ComponentProps, useEffect } from 'react';
import { z } from 'zod';

const ErrorComponent: ErrorRouteComponent = ({
  error,
}: ComponentProps<ErrorRouteComponent>) => {
  const router = useRouter();
  const queryErrorResetBoundary = useQueryErrorResetBoundary();

  useEffect(() => {
    // Reset the query error boundary
    queryErrorResetBoundary.reset();
  }, [queryErrorResetBoundary]);

  return (
    <VStack>
      {formatServerError(error)}
      <Button
        onClick={() => {
          // Invalidate the route to reload the loader, and reset any router error boundaries
          void router.invalidate();
        }}
      >
        retry
      </Button>
    </VStack>
  );
};

export const Route = createFileRoute('/$lang/classifieds-content/edit/$editID')(
  {
    component: EditPage,
    beforeLoad: ({ params }) => {
      const validationResult = z.string().uuid().safeParse(params.editID);

      if (!validationResult.success) {
        notification.error({
          message: 'Error',
          description: 'Invalid template ID',
        });
        throw notFound();
      }
    },
    loader: ({ context: { queryClient }, params: { editID } }) => {
      void queryClient.ensureQueryData({
        queryFn: useGetDictionariesQuery.fetcher(),
        queryKey: useGetDictionariesQuery.getKey(),
      });
      void queryClient.ensureQueryData({
        queryFn: useGetContentTemplateQuery.fetcher({ uuid: editID }),
        queryKey: useGetContentTemplateQuery.getKey({ uuid: editID }),
      });
    },
    errorComponent: ErrorComponent,
    onCatch: (error) => {
      notification.error({
        message: "Can't load the template",
        description: formatServerError(error),
      });
    },
  },
);
