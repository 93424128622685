import filetype from 'magic-bytes.js';
import { err, ok, Result } from 'neverthrow';

const getGreatestCommonDivisor = (a: number, b: number): number =>
  b === 0 ? a : getGreatestCommonDivisor(b, a % b);

type Filetype = File | Blob;
export type ChecksBeforeUploadProps = {
  file: Filetype;
  validations: {
    filesize?: { min: number; max: number };
    allowedTypes?: string[];
    allowedAspectRatios?: string[];
  };
};

export const isFilesizeAllowed = (
  fileSize: number,
  size: Required<ChecksBeforeUploadProps['validations']>['filesize'],
): boolean => {
  return fileSize >= size.min && fileSize <= size.max;
};

export const isAllowedFileType = async (
  file: File | Blob,
  allowedTypes: string[],
): Promise<boolean> => {
  const arrBuffer = await file.arrayBuffer();
  const currentFileTypes = filetype(new Uint8Array(arrBuffer));

  if (!currentFileTypes.length) {
    console.error('File type could not be determined', file);
    return false;
  }

  return currentFileTypes.some(
    (fileType) =>
      allowedTypes.includes(fileType.extension ?? fileType.typename) ||
      allowedTypes.includes(fileType.typename),
  );
};

export const isAllowedAspectRatio = async (
  file: File | Blob,
  allowedRatios: string[],
): Promise<boolean> => {
  const image = new Image();
  image.src = URL.createObjectURL(file);
  await image.decode();
  const { naturalWidth, naturalHeight } = image;
  const gcd = getGreatestCommonDivisor(naturalWidth, naturalHeight);
  const aspectRatioOfImage = `${naturalWidth / gcd}:${naturalHeight / gcd}`;
  return allowedRatios.includes(aspectRatioOfImage);
};

export const checksBeforeUpload = async ({
  file,
  validations,
}: ChecksBeforeUploadProps): Promise<
  Result<ChecksBeforeUploadProps['file'], string[]>
> => {
  const errMessages: string[] = [];
  //check filesize
  const filesize = file.size;
  if (
    validations.filesize &&
    !isFilesizeAllowed(filesize, validations.filesize)
  ) {
    if (filesize > validations.filesize.max)
      errMessages.push(
        `File too large! Max size: ${validations.filesize.max / 1024 / 1024} MB`,
      );

    if (filesize < validations.filesize.min)
      errMessages.push(
        `File too small! Min size: ${validations.filesize.min / 1024} KB`,
      );
  }

  //check magic-bytes
  if (
    validations.allowedTypes &&
    !(await isAllowedFileType(file, validations.allowedTypes))
  ) {
    errMessages.push(
      `Only accepts ${validations.allowedTypes.join(', ')} format` as const,
    );
  }

  //check aspectRatio
  if (
    validations.allowedAspectRatios &&
    !(await isAllowedAspectRatio(file, validations.allowedAspectRatios))
  ) {
    errMessages.push(
      `Only accepts ${validations.allowedAspectRatios.join(', ')} aspect ratio` as const,
    );
  }

  if (errMessages.length > 0) return err(errMessages);

  return ok(file);
};
