import { EditFormSchemaType } from '@features/edit-page/model/edit-form-model';
import { ZustandUppyEditStoreMapper } from '@features/edit-page/stores/uppy-zustand-store';
import {
  Category,
  ContentTemplateValidationConfig,
  PlaceholderImage,
  UploadType,
  useGetPresignedUrlsQuery,
} from '@gql_codegen/classifieds-content-types';
import AwsS3 from '@uppy/aws-s3';
import Uppy, { UppyFile } from '@uppy/core';
import DropTarget from '@uppy/drop-target';
import { useUppyEvent } from '@uppy/react';
import ThumbnailGenerator from '@uppy/thumbnail-generator';
import { App } from 'antd';
import { err, ok, Result, ResultAsync } from 'neverthrow';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  checksBeforeUpload,
  ChecksBeforeUploadProps,
} from '../domain/checks-before-upload';
import { useAccountQuery } from './use-account-id';

const findUppyFileByPlaceholder = (
  files: UppyFile<Record<string, unknown>, Record<string, unknown>>[],
  placeholder: PlaceholderImage,
): Result<
  UppyFile<Record<string, unknown>, Record<string, unknown>>,
  false
> => {
  const urlPathname = new URL(placeholder.url).pathname;
  const file = files.find(
    (file) =>
      file.uploadURL?.includes(placeholder.url) ??
      file.preview?.includes(urlPathname) ??
      file.name?.includes(urlPathname),
  );

  if (!file) return err(false);

  return ok(file);
};

export type UppyFileMeta = {
  accountId: string;
  category: Category;
  uuid: string;
};
export type UppyFileBody = Record<string, unknown>;

export type UppyWithFormFile = UppyFile<UppyFileMeta, UppyFileBody>;
export type UppyWithForm = Uppy<UppyFileMeta, UppyFileBody>;
export type createUppyProps = {
  fileLimit?: number;
};

const createUppy = () =>
  new Uppy<UppyFileMeta, UppyFileBody>({
    store: new ZustandUppyEditStoreMapper(),
    autoProceed: true,
    restrictions: {
      maxNumberOfFiles: null,
      requiredMetaFields: [
        'accountId',
        'category',
        'uuid',
      ] satisfies (keyof UppyFileMeta)[],
    },
  })
    .use(DropTarget<UppyFileMeta, UppyFileBody>, {
      target: document.body,
    })
    .use(ThumbnailGenerator<UppyFileMeta, UppyFileBody>, {
      waitForThumbnailsBeforeUpload: true,
      thumbnailWidth: 500,
    })
    .use(AwsS3<UppyFileMeta, UppyFileBody>, {
      endpoint: '',
      shouldUseMultipart: false,
      getUploadParameters: async (file) => {
        const urlResult = await ResultAsync.fromPromise(
          useGetPresignedUrlsQuery.fetcher({
            accountId: file.meta.accountId,
            category: file.meta.category,
            fileNames: `${file.meta.uuid}.${file.extension}`,
            uploadType: UploadType.Placeholder,
          })(),
          (err) =>
            new Error(`[Uppy::AwsS3]=> failed get presigned url`, {
              cause: err,
            }),
        );

        if (urlResult.isErr()) throw urlResult.error;
        const url = urlResult.value.getPresignedUrls[0]?.url;
        if (typeof url !== 'string') throw new Error('[Uppy::AwsS3]=> no url');

        return {
          method: 'PUT',
          url,
          fields: {},
          headers: {
            'x-amz-acl': 'bucket-owner-full-control',
            'content-type': file.type,
          },
        };
      },
    });

type useUppyProps = {
  validationConfig?: ContentTemplateValidationConfig | undefined;
};

export const useUppyWithEditForm = (props: useUppyProps) => {
  const { notification } = App.useApp();

  const [uppy] = useState(() => createUppy());
  const accountQuery = useAccountQuery();
  const validations = useMemo((): ChecksBeforeUploadProps['validations'] => {
    if (!props.validationConfig) return {};
    return {
      allowedAspectRatios: [props.validationConfig.imageAspectRatio],
      allowedTypes: [props.validationConfig.placeholderImageType],
      filesize: {
        max: props.validationConfig.imageMaxSize,
        min: props.validationConfig.imageMinSize,
      },
    };
  }, [props.validationConfig]);

  const { control, getValues, setValue, trigger } =
    useFormContext<EditFormSchemaType>();

  const category = useWatch({
    control,
    name: 'category',
  });

  const placeholders = useWatch({
    control,
    name: 'placeholders',
  });

  useEffect(() => {
    placeholders.map((placeholder) => {
      if (placeholder.file) return;

      const file = findUppyFileByPlaceholder(uppy.getFiles(), placeholder);
      if (file.isOk()) return;

      const fileId = uppy.addFile({
        data: { size: null },
        name: placeholder.url,
        uploadURL: placeholder.url,
        isRemote: true,
        meta: {
          accountId: accountQuery.data?.id ?? '',
          category: category,
          uuid: crypto.randomUUID(),
        },
      });

      uppy.setFileState(fileId, {
        progress: {
          uploadComplete: true,
          bytesTotal: null,
          bytesUploaded: 0,
          uploadStarted: +new Date(),
        },
      });
    });
  }, [accountQuery.data?.id, category, placeholders, uppy]);

  useEffect(() => {
    if (props.validationConfig?.maxPlaceholdersAllowed)
      uppy.setOptions({
        restrictions: {
          maxNumberOfFiles: props.validationConfig.maxPlaceholdersAllowed,
        },
      });
  }, [props.validationConfig?.maxPlaceholdersAllowed, uppy]);

  useUppyEvent(uppy, 'thumbnail:generated', (file, preview) => {
    const placeholders = getValues(`placeholders`);

    const placeholderIdx = placeholders.length;

    const position =
      getValues(`placeholders.${placeholderIdx}.position`) ||
      placeholderIdx + 1;

    setValue(`placeholders.${placeholderIdx}`, {
      position,
      url: preview,
      file,
    });
  });

  useUppyEvent(uppy, 'upload-success', (file, { uploadURL }) => {
    const placeholders = getValues(`placeholders`);

    if (!uploadURL)
      return console.error('[Uppy::upload-success] => no upload url');

    const placeholderIdx = placeholders.findIndex(
      (placeholder) =>
        file && findUppyFileByPlaceholder([file], placeholder).isOk(),
    );

    if (placeholderIdx === -1) return;

    const position =
      getValues(`placeholders.${placeholderIdx}.position`) ||
      placeholderIdx + 1;

    setValue(`placeholders.${placeholderIdx}`, {
      position,
      url: uploadURL,
      file,
    });
    void trigger('placeholders');
  });

  useUppyEvent(uppy, 'upload-error', (file, error) => {
    const placeholders = getValues(`placeholders`);

    notification.error({
      message: (
        <span data-qa-selector="edit-form-upload-error">{error.message}</span>
      ),
    });
    if (!file?.id) return console.error('[Uppy::upload-error]=> no file id!!!');

    const placeholderIdx = placeholders.findIndex(
      (placeholder) => placeholder.url === file.name,
    );

    if (placeholderIdx === -1) return;
    const newPlaceholders = placeholders.filter(
      (_, idx) => idx !== placeholderIdx,
    );
    setValue('placeholders', newPlaceholders);
    uppy.removeFile(file.id);
  });

  const handleFileUpload = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;
      if (!files)
        return console.info(
          '[FileUpload::handleFileUpload]=> no files were provided',
        );

      for (const file of Array.from(files)) {
        checksBeforeUpload({
          file,
          validations,
        })
          .then((checksResult) => {
            if (checksResult.isErr()) {
              checksResult.error.map((error) =>
                notification.error({
                  message: (
                    <span data-qa-selector="edit-form-validation-error">
                      {error}
                    </span>
                  ),
                }),
              );
              return;
            }

            uppy.addFile({
              name: file.name,
              type: file.type,
              data: file,
              meta: {
                accountId: accountQuery.data?.id ?? '',
                category: category,
                uuid: crypto.randomUUID(),
              },
            });
          })
          .catch((err: unknown) => {
            console.error(
              '[FileUpload::handleFileUpload::checksBeforeUpload]=>',
              err,
            );
            notification.error({
              message:
                err instanceof Error ? err.message : 'Some validation error',
            });
          });
      }
      event.target.value = '';
    },
    [accountQuery.data?.id, category, notification, uppy, validations],
  );

  const handleFileDelete = (fieldIdx: number) => {
    const placeholders = getValues(`placeholders`);

    if (!placeholders[fieldIdx]) return;

    const file = findUppyFileByPlaceholder(
      uppy.getFiles(),
      placeholders[fieldIdx],
    );

    if (file.isErr()) return;

    const newPlaceholders = placeholders.filter((_, idx) => idx !== fieldIdx);
    setValue('placeholders', newPlaceholders);
    uppy.removeFile(file.value.id);
  };

  const clearUppy = () => {
    uppy.cancelAll();
    uppy.clear();
  };

  return {
    handleFileUpload,
    handleFileDelete,
    placeholders,
    clearUppy,
  };
};
