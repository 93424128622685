import { Button, Space, Typography } from 'antd';
import { OverviewTable } from './overview-table';
import { Link } from '@tanstack/react-router';
import { Route as CreateRoute } from '@routes/$lang/classifieds-content/create';

export const OverviewPage = () => {
  return (
    <Space direction="vertical" size="large">
      <Typography.Title>Classifieds Content Template Overview</Typography.Title>
      <Link to={CreateRoute.to} params={{ lang: 'en' }}>
        <Button
          type="primary"
          data-qa-selector="overview-add-new-template-button"
          size="large"
        >
          Add New Template
        </Button>
      </Link>
      <OverviewTable />
    </Space>
  );
};
