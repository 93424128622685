import { EditOutlined } from '@ant-design/icons';
import {
  ContentTemplate,
  useGetTemplatesQuery,
} from '@gql_codegen/classifieds-content-types';
import { Route as EditRoute } from '@routes/$lang/classifieds-content/edit/$editID';
import { useConvertGeneratedQueryToSuspense } from '@src/shared/hooks/use-convert-generated-query-to-suspence';
import { Link } from '@tanstack/react-router';
import { Button, Table, TableColumnType } from 'antd';
import { HTMLAttributes } from 'react';

const columns: TableColumnType<ContentTemplate>[] = [
  {
    title: 'Template name',
    dataIndex: 'name',
    width: 10,
    render: (value) => <span data-qa-selector="overview-name">{value}</span>,
    ellipsis: true,
  },
  {
    title: 'Country',
    dataIndex: 'country',
    width: 2,
    render: (value) => <span data-qa-selector="overview-country">{value}</span>,
    ellipsis: true,
  },
  {
    title: 'Platform',
    dataIndex: 'platform',
    width: 3,
    render: (value) => (
      <span data-qa-selector="overview-platform">{value}</span>
    ),
    ellipsis: true,
  },
  {
    title: 'Account',
    dataIndex: 'account',
    width: 10,
    render: (value) => <span data-qa-selector="overview-account">{value}</span>,
    ellipsis: true,
  },
  {
    title: 'Category',
    dataIndex: 'category',
    width: 3,
    render: (value) => (
      <span data-qa-selector="overview-category">{value}</span>
    ),
    ellipsis: true,
  },
  {
    title: '',
    align: 'center',
    width: 2,
    render: (_, record) => {
      return (
        <Link
          to={EditRoute.to}
          params={{ lang: 'en', editID: record.id }}
          data-qa={record.id}
        >
          <Button
            type="primary"
            data-qa-selector="overview-edit-button"
            data-qa-selector-name={record.name}
          >
            <EditOutlined />
          </Button>
        </Link>
      );
    },
  },
];

export const OverviewTable = () => {
  const { data, isLoading } = useConvertGeneratedQueryToSuspense(
    useGetTemplatesQuery,
    undefined,
  );

  return (
    <Table<ContentTemplate>
      dataSource={data.getContentTemplates}
      columns={columns}
      pagination={false}
      tableLayout="fixed"
      loading={isLoading}
      rowKey="id"
      onRow={(data, idx) => {
        return {
          'data-qa-selector': 'overview-table-row',
          'data-qa-selector-idx': idx,
          'data-qa-selector-name': data.name,
          'data-qa-selector-id': data.id,
        } as HTMLAttributes<HTMLTableRowElement>;
      }}
    />
  );
};
