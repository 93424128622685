import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { EditFormSchemaType } from '@features/edit-page/model/edit-form-model';
import { useUppyZustandEditStore } from '@features/edit-page/stores/uppy-zustand-store';
import { FormItemWithRules } from '@src/shared/components/form-item-with-rules';
import { css } from '@styled-system/css';
import { Bleed, Grid, VStack } from '@styled-system/jsx';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/drop-target/dist/style.css';
import { Button, Image, InputNumber, Typography } from 'antd';
import { Progress } from 'antd/lib';
import { list } from 'radash';
import { useRef } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { useValidationConfigQuery } from './hooks/use-validation-dictionary';

type FileUploadProps = {
  handleFileUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleFileDelete: (fieldIdx: number) => void;
  fileLimit: { min: number; max: number };
  placeholders: EditFormSchemaType['placeholders'];
};

export const FileUpload = (props: FileUploadProps) => {
  const files = useUppyZustandEditStore((state) => state.files);
  const validationConfigsQuery = useValidationConfigQuery();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const { control } = useFormContext<EditFormSchemaType>();

  const { errors } = useFormState({
    control,
    name: 'placeholders',
    exact: true,
  });

  return (
    <VStack alignItems="start">
      <VStack alignItems="start" data-qa-selector="edit-form-upload">
        <input
          type="file"
          accept="image/*"
          ref={fileInputRef}
          className={css({ display: 'none !important' })}
          multiple
          onChange={props.handleFileUpload}
        />
        <Button
          disabled={props.placeholders.length >= props.fileLimit.max}
          icon={<UploadOutlined />}
          onClick={() => fileInputRef.current?.click()}
          data-qa-selector="edit-form-upload-button"
          className={css({ my: 4 })}
          type="primary"
          size="large"
        >
          Upload
          <span>
            (Min: {props.fileLimit.min} - Max: {props.fileLimit.max}{' '}
            placeholders)
          </span>
        </Button>
        <Typography.Text
          type="danger"
          role="alert"
          style={{
            visibility: errors.placeholders?.root ? 'visible' : 'hidden',
          }}
          data-qa-selector="edit-form-placeholder-error"
        >
          {errors.placeholders?.root?.message}
        </Typography.Text>
      </VStack>

      <Grid alignItems="start" gap={8} gridTemplateColumns={4}>
        {props.placeholders.map((placeholder, idx) => {
          const file = Object.values(files).find(
            (file) =>
              file.preview === placeholder.url ||
              file.uploadURL?.includes(placeholder.url),
          );

          return (
            <VStack key={`${idx}_${file?.id ?? ''}`} alignItems="start" gap={2}>
              <Progress
                percent={file?.progress.percentage ?? 0}
                style={{
                  visibility:
                    file?.progress.uploadStarted &&
                    !file.progress.uploadComplete
                      ? 'visible'
                      : 'hidden',
                }}
                data-qa-selector="edit-form-placeholder-progressbar"
              />

              <FormItemWithRules
                controllerProps={{
                  control,
                  name: `placeholders.${idx}.position`,
                  rules: {
                    deps: list(props.placeholders.length - 1).map(
                      (placeholderIdx) =>
                        `placeholders.${placeholderIdx}.position` as const,
                    ),
                  },
                }}
                label="Position"
                required
                help={`Min: ${
                  validationConfigsQuery.data.config.minPlaceholderPosition
                } - Max: ${
                  validationConfigsQuery.data.config.maxPlaceholderPosition
                }`}
                data-qa-selector="edit-form-placeholder-position"
              >
                <InputNumber
                  min={
                    validationConfigsQuery.data.config.minPlaceholderPosition
                  }
                  max={
                    validationConfigsQuery.data.config.maxPlaceholderPosition
                  }
                  data-qa-selector="edit-form-placeholder-position-input"
                  className={css({ width: '120px' })}
                  addonAfter={
                    <Bleed inline={'11px'}>
                      <Button
                        onClick={() => props.handleFileDelete(idx)}
                        data-qa-selector="edit-form-placeholder-delete-button"
                      >
                        <DeleteOutlined
                          style={{ color: 'red', fontSize: '18px' }}
                        />
                      </Button>
                    </Bleed>
                  }
                />
              </FormItemWithRules>

              {(file?.preview ?? placeholder.url) && (
                <Image
                  src={file?.preview ?? placeholder.url}
                  data-qa-selector="edit-form-placeholder-image"
                />
              )}
            </VStack>
          );
        })}
      </Grid>
    </VStack>
  );
};

export default FileUpload;
